import { ReactNode } from "react";

import "./index.scss";

export const RepeatingFields = <Item extends object>(
  { addItemFunction, removeItemFunction, items, itemElement, label, className, readonly, disableDelete, disableAdd }:
  { addItemFunction?: () => void, removeItemFunction?: (index: number) => void, items: Item[], itemElement: (item: Item, index: number) => ReactNode, label?: ReactNode, className?: string, readonly?: boolean, disableDelete?: boolean, disableAdd?: boolean },
) => {
  if (readonly) {disableDelete = true; disableAdd = true;}
  return <div style={{ pointerEvents: readonly ? "none" : "unset" }} className={`RepeatingFields ${className || ""}`}>
    <label>{label || null} {readonly &&  "🔒"}</label>
    <div className="items">
      {items.map((item, index) => <div className="item" key={index}>
        <div className="itemFields">
          {itemElement(item, index)}
        </div>
        {!disableDelete && <div className="itemActions">
          <button onClick={() => {if (removeItemFunction) removeItemFunction(index);}}>Delete this item</button>
        </div>}
      </div>)}
    </div>
    <div className="groupActions">
      <div className="wrapper">
        {!disableAdd && <button onClick={addItemFunction || (() => {})}>
            + Add
        </button>}
      </div>
    </div>
  </div>;
};
