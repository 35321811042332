import { useRef } from "react";
import { AriaListBoxProps, mergeProps, useFocusRing, useListBox, useOption } from "react-aria";
import { Item, ListState, Node as StatelyNode, useListState } from "react-stately";

import "./index.scss";

import { ReactComponent as CheckSvg } from "./check.svg";

type ItemType = { };

const Option = ({ item, state, readonly }: {
  item: StatelyNode<ItemType>;
  state: ListState<ItemType>;
  readonly?: boolean,
}) => {
  // Get props for the option element
  const ref = useRef(null);
  const { optionProps, isSelected, isDisabled } = useOption(
    { key: item.key },
    state,
    ref,
  );

  // Determine whether we should show a keyboard
  // focus ring for accessibility
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <li
      className={`Option ${isSelected ? "isSelected" : ""} ${isDisabled ? "isDisabled" : ""} ${isFocusVisible ? "isFocusVisible" : ""}`}
      {...mergeProps(optionProps, focusProps)}
      ref={ref}
      aria-readonly={readonly}
      style={{ pointerEvents: readonly ? "none" : "unset" }}
    >
      <span className="selectionIndicator"><CheckSvg /></span> {item.rendered}
    </li>
  );
};

export const ListBox = <T extends ItemType>(props: AriaListBoxProps<T> & { className?: string, hideBox?: boolean, readonly?: boolean }) => {
  // Create state based on the incoming props
  const state = useListState(props);
  // Get props for the listbox element
  const ref = useRef(null);
  const { listBoxProps, labelProps } = useListBox(props, state, ref);

  return (
    <div className="ListBox">
      <label {...labelProps}>{props.label} {props.readonly && "🔒"}</label>
      <ul
        className={`selection-${props.selectionMode} ${props.className || ""} ${props.hideBox ? "hideBox" : ""}`}
        {...listBoxProps}
        ref={ref}
      >
        {[...Array.from(state.collection)].map((item) => (
          <Option key={item.key} item={item} state={state} readonly={props.readonly} />
        ))}
      </ul>
    </div>
  );
};

export const SelectionBox = <T extends ItemType>({ items, ...props }: Omit<AriaListBoxProps<T>, "children"> & { className?: string, hideBox?: boolean, readonly?: boolean, items: { value: string, label: string }[] }) => {
  return <ListBox {...props}>
    {items.map((item) => <Item key={item.value}>{item.label}</Item>)}
  </ListBox>;
};
