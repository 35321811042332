import { z } from "zod";
import { multiLanguageStringSchema } from "./multiLanguageString";

export const pinpointSchema = z.object({
  _id: z.string(),
  analyticsName: z.string().optional(),
  name: multiLanguageStringSchema,
  museums: z.string().array().optional(),
  photo: z.string().optional(),
  text: multiLanguageStringSchema.optional(),
  position: z.object({
    lat: z.number(),
    lng: z.number(),
  }),
  medias: z.string().array().default([]),
});
