export const getBase64 = (blob: Blob) => new Promise((resolve: (value: string) => void, reject) => {
  try {
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;
      if (dataUrl && typeof dataUrl === "string") { 
        resolve(dataUrl);
      }
    };
    reader.readAsDataURL(blob);
  } catch (error) {
    reject(error);
  }
});
