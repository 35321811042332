import { useEffect, useState } from "react";
import { User, remoteCall } from "../../App";
import cloneDeep from "clone-deep";

import "./index.scss";
import { pinpointSchema as schema } from "../../types/pinpoint";
import { z } from "zod";
import { multiLanguageString } from "../../utils/multiLanguageString";
import { ListItem } from "../../components/ListItem";
import { Modal } from "../../components/Modal";
import { TextField } from "../../components/TextField";
import { changeHandlers } from "../../utils/changeHandlers";
import { MultiLanguageTextField } from "../../components/MultiLanguageTextField";
import { SelectionBox } from "../../components/ListBox";
import { museumSchema } from "../../types/museum";
import { MultiLanguageRichTextField } from "../../components/MultiLanguageRichTextField";
import { Base64ImageField } from "../../components/Base64ImageField";
import { MapField } from "../../components/MapField";
import { mediaSchema } from "../../types/media";

type Single = z.infer<typeof schema>;
type Museum = z.infer<typeof museumSchema>;
type Media = z.infer<typeof mediaSchema>;

const emptySingle: Single = {
  _id: "",
  name: [],
  position: {
    lat: 0,
    lng: 0,
  },
  medias: [],
};

const Form = ({ givenCurrent, saveSingle, deleteSingle, museums, medias }: { givenCurrent: Single, saveSingle: (newSingle: Single) => void, deleteSingle: () => {}, museums: Museum[], medias: Media[] }) => {
  const [current, setCurrent] = useState(cloneDeep(givenCurrent));

  const { parseTextChange, parseSingleSelectionChange, parseMultipleSelectionChange, parsePositionChange, addItem, removeItem } = changeHandlers(setCurrent);

  return <div className="form">
    {current._id !== "" && <>
      <TextField label="ID" value={current._id} onChange={parseTextChange("_id")} isReadOnly />
      <hr/>
    </>}

    <TextField label="Analytics name" value={current.analyticsName} onChange={parseTextChange("analyticsName")} />

    <MultiLanguageTextField 
      label="Name"
      property="name"
      items={current.name}
      {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
    />

    <MultiLanguageRichTextField
      label="Text"
      property="text"
      items={current.text || []}
      {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
    />

    <SelectionBox 
      label="Museums"
      selectionMode="multiple" 
      defaultSelectedKeys={current.museums || []} 
      onSelectionChange={parseMultipleSelectionChange("museums")} 
      items={museums.map((museum) => ({ value: museum._id, label: multiLanguageString(museum.name) }))}
    />

    <MapField label="Position" position={current.position} onChange={parsePositionChange("position")} />

    <Base64ImageField 
      label="Photo"
      value={current.photo || ""}
      format="image/jpeg"
      description="Squared photo. JPEG. Min width 600px."
      rasterResizeOptions={{ width: 600 }}
      onChange={parseTextChange("photo")}
    />

    <SelectionBox 
      label="Medias"
      selectionMode="multiple" 
      defaultSelectedKeys={current.medias || []} 
      onSelectionChange={parseMultipleSelectionChange("medias")} 
      items={medias.map((media) => ({ value: media._id, label: multiLanguageString(media.title) }))}
    />

    <div className="modalActions">
      <button onClick={() => {saveSingle(current);}}>Save</button>
      {current._id !== "" && <button onClick={() => {deleteSingle();}}>Delete</button>}
    </div>
  </div>;
};

export const Pinpoints = (
  { user }: 
  { user: User }) => {
  if (!user.privileges.includes("pinpoints")) return <div>Access denied</div>;

  const [items, setItems] = useState<Single[]>();
  const [museums, setMuseums] = useState<Museum[]>();
  const [medias, setMedias] = useState<Media[]>();
  
  const [givenCurrent, setGivenCurrent] = useState<Single | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const museumCall = await remoteCall("museumList", {});
        if (museumCall.museums) setMuseums(museumCall.museums);
        const mediaCall = await remoteCall("mediaList", {});
        if (mediaCall.medias) setMedias(mediaCall.medias);

        const call = await remoteCall("pinpointList", {});
        if (call.pinpoints) setItems(call.pinpoints);
      } catch (error) {}
    })();
  }, []);

  const openSingle = (single: Single) => {
    setGivenCurrent(single);
  };

  const closeSingle = () => {
    setGivenCurrent(undefined);
  };

  const saveSingle = async (newSingle: Single) => {
    try {
      const parsedSingle = schema.parse(newSingle);
      await remoteCall("pinpointUpdate", parsedSingle);
      const call = await remoteCall("pinpointList", { forceRenewCache: true });
      if (call.pinpoints) setItems(call.pinpoints);
      closeSingle();
    } catch (error) {
      if ((error as Error).name === "ZodError") {
        console.log(error);
      }
      console.log(error);
    }
  }; 

  const deleteSingle = async () => {
    if (givenCurrent && givenCurrent._id) {
      const confirm = window.confirm("Do you really want to delete?");
      if (confirm) {
        await remoteCall("pinpointDelete", { _id: givenCurrent._id });
        const call = await remoteCall("pinpointList", { forceRenewCache: true });
        if (call.pinpoints) setItems(call.pinpoints);
        closeSingle();
      }
    }
  };

  const createNew = () => {
    setGivenCurrent(emptySingle);
  };
  
  return <div className="Pinpoints screen">
    <h1>Pinpoints</h1>
    <div className="actions">
      <button onClick={createNew}>New</button>
    </div>
    
    {items 
      && items.map((single) => 
        <ListItem 
          key={single._id} 
          title={multiLanguageString(single.name)} 
          onClick={() => openSingle(single)}
        />,
      )}
    
    <Modal closeModal={closeSingle} isOpen={!!givenCurrent} title="Edit pinpoint">
      {givenCurrent && museums && medias && <Form {...{ givenCurrent, saveSingle, deleteSingle, museums, medias }} />}
    </Modal>
  </div>;
};
