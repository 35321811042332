import { useEffect, useState } from "react";
import { User, remoteCall } from "../../App";
import cloneDeep from "clone-deep";

import "./index.scss";
import { stationSchema as schema } from "../../types/station";
import { z } from "zod";
import { multiLanguageString } from "../../utils/multiLanguageString";
import { ListItem } from "../../components/ListItem";
import { Modal } from "../../components/Modal";
import { useParams } from "react-router-dom";
import { changeHandlers } from "../../utils/changeHandlers";
import { TextField } from "../../components/TextField";
import { MultiLanguageRichTextField } from "../../components/MultiLanguageRichTextField";
import { characterSchema } from "../../types/character";
import { RepeatingFields } from "../../components/RepeatingFields";
import { mediaSchema } from "../../types/media";
import { itemSchema } from "../../types/item";
import { MultiLanguageTextField } from "../../components/MultiLanguageTextField";
import { MultiLanguageBase64ImageField } from "../../components/MultiLanguageBase64ImageField";
import { ToggleField } from "../../components/ToggleField";
import { SelectionBox } from "../../components/ListBox";
import { NumberField } from "../../components/NumberField";

type Single = (z.infer<typeof schema>)["moments"][number];
type Character = (z.infer<typeof characterSchema>);
type Media = (z.infer<typeof mediaSchema>);
type Item = (z.infer<typeof itemSchema>);

/* const emptySingle: Single = {
  _id: "",
  name: [],
  position: {
    lat: 0,
    lng: 0,
  },
  medias: [],
}; */

const Form = ({ givenCurrent, givenCurrentIndex, saveSingle, characters, medias, items }: { givenCurrent: Single, givenCurrentIndex: number, saveSingle: (newSingle: Single, index: number) => void, characters: Character[], medias: Media[], items: Item[] }) => {
  const [current, setCurrent] = useState(cloneDeep(givenCurrent));

  const { parseTextChange, parseNumberChange, parseSingleSelectionChange, parseBooleanChange, addItem, removeItem } = changeHandlers(setCurrent);

  return <div className="form">
    <TextField label="Type" value={current.type} onChange={parseTextChange("type")} isReadOnly />

    {current.type === "dialogue"
      && <>
        <label className="label">Character - Mood</label>
        <p>{multiLanguageString(characters.find((singleCharacter) => singleCharacter._id === current.characterId)?.name)} - {current.moodShortName}</p>
        <MultiLanguageRichTextField 
          label="Dialogue Text"
          property="dialogueText"
          items={current.dialogueText || []}
          {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
        />
      </>}

    {current.type === "additionalMedia"
      && <>
        <label className="label">Character</label>
        <p>{multiLanguageString(characters.find((singleCharacter) => singleCharacter._id === current.characterId)?.name)}</p>
        <MultiLanguageRichTextField 
          label="Dialogue Text"
          property="dialogueText"
          items={current.dialogueText || []}
          {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
        />
        <RepeatingFields
          label="Medias"
          items={current.medias || []}
          disableAdd
          disableDelete
          itemElement={(item, index) => <>
            <label className="label">Title</label>
            <p style={{ fontWeight: "bold" }}>{multiLanguageString(medias.find((singleMedia) => singleMedia._id === item.media)?.title)}</p>
            {item.itemGiven && <>
              <p>
                This media gives:{" "}
                {multiLanguageString(items.find((singleItem) => singleItem._id === item.itemGiven?.itemId)?.name)} |
                awarded by {multiLanguageString(characters.find((singleCharacter) => singleCharacter._id === item.itemGiven?.characterId)?.name)}
              </p>
              <MultiLanguageRichTextField 
                label="Item given dialogue"
                property={`medias.${index}.itemGiven.dialogueText`}
                items={item.itemGiven.dialogueText || []}
                {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
              />
            </>}
          </>}
        />
      </>
    }

    {current.type === "partialQuestItem"
      && <>
        <MultiLanguageTextField 
          label="Name"
          property="name"
          items={current.name || []}
          {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
        />

        <MultiLanguageBase64ImageField
          label="Image"
          property="image"
          items={current.image || []}
          format="image/png"
          description="Squared image. PNG. Min width 800px."
          rasterResizeOptions={{ width: 900 }}
          {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
        />
      </>
    }
    
    {current.type === "itemGiven"
      && <>
        <label className="label">Character</label>
        <p>{multiLanguageString(characters.find((singleCharacter) => singleCharacter._id === current.characterId)?.name)}</p>
        
        <label className="label">Item</label>
        <p>{multiLanguageString(items.find((singleItem) => singleItem._id === current.itemId)?.name)}</p>
        
        <MultiLanguageRichTextField 
          label="Dialogue Text"
          property="dialogueText"
          items={current.dialogueText || []}
          {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
        />
      </>
    }
    
    {current.type === "skinGiven"
      && <>
        <label className="label">Character</label>
        <p>{multiLanguageString(characters.find((singleCharacter) => singleCharacter._id === current.characterId)?.name)}</p>
        
        <label className="label">Skin</label>
        <p>{current.skin}</p>
        
        <MultiLanguageRichTextField 
          label="Dialogue Text"
          property="dialogueText"
          items={current.dialogueText || []}
          {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
        />
      </>
    }
    
    {current.type === "imageTapGame"
      && <>
        <TextField label="Analytics name" value={current.analyticsName} onChange={parseTextChange("analyticsName")} />
        <RepeatingFields
          label="Steps"
          items={current.steps || []}
          //@ts-expect-error
          addItemFunction={() => {addItem("steps", { clickableMap: [] });}}
          removeItemFunction={(i: number) => {removeItem("steps", i);}}
          itemElement={(item, index) => <>
            <SelectionBox
              label="Character"
              selectionMode="single" 
              defaultSelectedKeys={item.characterId ? [item.characterId] : []} 
              onSelectionChange={parseSingleSelectionChange(`steps.${index}.characterId`)} 
              items={characters.map((singleCharacter) => ({ value: singleCharacter._id, label: multiLanguageString(singleCharacter.name) }))}
            />

            <MultiLanguageRichTextField 
              label="Dialogue text"
              property={`steps.${index}.dialogueText`}
              items={item.dialogueText || []}
              {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
            />

            <MultiLanguageBase64ImageField
              label="Image"
              property={`steps.${index}.image`}
              items={item.image || []}
              format="image/png"
              description="Squared image. PNG. Min width 700px."
              rasterResizeOptions={{ width: 700 }}
              {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
            />

            <MultiLanguageTextField 
              readonly
              label="Video"
              property={`steps.${index}.video`}
              items={item.video || []}
              {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
            />

            <ToggleField
              label="Just one"
              isSelected={item.justOne || false}
              onChange={parseBooleanChange(`steps.${index}.justOne`)}
            />

            <RepeatingFields
              label="Hotspots"
              items={item.clickableMap || []}
              //@ts-expect-error
              addItemFunction={() => {addItem(`steps.${index}.clickableMap`, { top: 0, left: 0, width: 0, height: 0 });}}
              removeItemFunction={(i: number) => {removeItem(`steps.${index}.clickableMap`, i);}}
              itemElement={(clickableHotspot, hotspotIndex) => <>
                <NumberField 
                  label="Top"
                  value={clickableHotspot.top}
                  onChange={parseNumberChange(`steps.${index}.clickableMap.${hotspotIndex}.top`)}
                />
                <NumberField 
                  label="Left"
                  value={clickableHotspot.left}
                  onChange={parseNumberChange(`steps.${index}.clickableMap.${hotspotIndex}.left`)}
                />
                <NumberField 
                  label="Width"
                  value={clickableHotspot.width}
                  onChange={parseNumberChange(`steps.${index}.clickableMap.${hotspotIndex}.width`)}
                />
                <NumberField 
                  label="Height"
                  value={clickableHotspot.height}
                  onChange={parseNumberChange(`steps.${index}.clickableMap.${hotspotIndex}.height`)}
                />
              </>}
            />
          </>}
        />
      </>
    }

    {current.type === "orderingGame"
      && <>
        <TextField label="Analytics name" value={current.analyticsName} onChange={parseTextChange("analyticsName")} />
        <label className="label">Character</label>
        <p>{multiLanguageString(characters.find((singleCharacter) => singleCharacter._id === current.characterId)?.name)}</p>
        <MultiLanguageRichTextField 
          label="Dialogue Text"
          property="dialogueText"
          items={current.dialogueText || []}
          {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
        />

        <RepeatingFields
          label="Steps"
          items={current.steps || []}
          disableAdd
          disableDelete
          itemElement={(item, index) => <>
            <MultiLanguageTextField 
              label="Text"
              property={`steps.${index}.text`}
              items={item.text || []}
              {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
            />
            <MultiLanguageTextField 
              label="Placeholder label"
              property={`steps.${index}.placeholderLabel`}
              items={item.placeholderLabel || []}
              {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
            />
          </>}
        />
      </>}

    {current.type === "multipleChoiceGame"
      && <>
        <TextField label="Analytics name" value={current.analyticsName} onChange={parseTextChange("analyticsName")} />
        <RepeatingFields
          label="Questions"
          items={current.questions || []}
          disableAdd
          disableDelete
          itemElement={(item, index) => <>
            <label className="label">Character</label>
            <p>{multiLanguageString(characters.find((singleCharacter) => singleCharacter._id === item.characterId)?.name)}</p>
            <MultiLanguageRichTextField 
              label="Dialogue Text"
              property={`questions.${index}.dialogueText`}
              items={item.dialogueText || []}
              {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
            />
            <MultiLanguageBase64ImageField
              label="Image"
              property={`questions.${index}.image`}
              items={item.image || []}
              format="image/png"
              description="Squared image. PNG. Min width 700px."
              rasterResizeOptions={{ width: 700 }}
              {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
            />
            <MultiLanguageTextField 
              label="Alternative Text"
              property={`questions.${index}.alternativeText`}
              items={item.alternativeText || []}
              {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
            />

            <RepeatingFields
              label="Answers"
              items={item.answers || []}
              //@ts-expect-error
              addItemFunction={() => {addItem(`questions.${index}.answers`, {  });}}
              removeItemFunction={(i: number) => {removeItem(`questions.${index}.answers`, i);}}
              itemElement={(answer, answerIndex) => <>
                <MultiLanguageRichTextField 
                  label="Text"
                  property={`questions.${index}.answers.${answerIndex}.text`}
                  items={answer.text || []}
                  {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
                />
                <MultiLanguageBase64ImageField
                  label="Image"
                  property={`questions.${index}.answers.${answerIndex}.image`}
                  items={answer.image || []}
                  format="image/png"
                  description="Squared image. PNG. Min width 700px."
                  rasterResizeOptions={{ width: 700 }}
                  {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
                />
                <ToggleField 
                  label="Right answer"
                  isSelected={answer.right || false}
                  onChange={parseBooleanChange(`questions.${index}.answers.${answerIndex}.right`)}
                />
              </>}
            />
          </>}
        />
      </>}

    <div className="modalActions">
      <button onClick={() => {saveSingle(current, givenCurrentIndex);}}>Save</button>
      {/* {current._id !== "" && <button onClick={() => {deleteSingle();}}>Delete</button>} */}
    </div>
  </div>;
};

export const Moments = (
  { user, type }: 
  { user: User, type: "station" | "onboardingStation" }) => {
  if (!user.privileges.includes("stations")) return <div>Access denied</div>;

  const [items, setItems] = useState<Single[]>();
  const [characters, setCharacters] = useState<Character[]>();
  const [medias, setMedias] = useState<Media[]>();
  const [winItems, setWinItems] = useState<Item[]>();
  const [stationName, setStationName] = useState<{ lang: "it" | "en", string: string }[]>();
  const [stationId, setStationId] = useState<string>();
  
  const [givenCurrent, setGivenCurrent] = useState<Single | undefined>();
  const [givenCurrentIndex, setGivenCurrentIndex] = useState<number | undefined>();

  const { stationId: queryStationId } = useParams();

  useEffect(() => {setStationId(queryStationId);}, [queryStationId]);

  useEffect(() => {
    (async () => {
      if (stationId) {
        try {
          const [characterCall, mediaCall, itemCall] = await Promise.all([
            remoteCall("characterList", { }),
            remoteCall("mediaList", { }),
            remoteCall("itemList", { }),
          ]);
          setCharacters(characterCall.characters);
          setMedias(mediaCall.medias);
          setWinItems(itemCall.items);

          if (type === "station") {
            const call = await remoteCall("stationList", { station: stationId });
            if (call.stations && call.stations[0]) setStationName(call.stations[0].name);
            if (call.stations && call.stations[0].moments) setItems(call.stations[0].moments);
          } else if (type === "onboardingStation") {
            const call = await remoteCall("onboardingStationList", { station: stationId });
            if (call.onboardingStations && call.onboardingStations[0]) setStationName(call.onboardingStations[0].name);
            if (call.onboardingStations && call.onboardingStations[0].moments) setItems(call.onboardingStations[0].moments);
          }
        } catch (error) {}
      }
    })();
  }, [stationId]);

  const openSingle = (single: Single, index: number) => {
    setGivenCurrent(single);
    setGivenCurrentIndex(index);
  };

  const closeSingle = () => {
    setGivenCurrent(undefined);
    setGivenCurrentIndex(undefined);
  };

  const saveSingle = async (newSingle: Single, index: number) => {
    try {
      if (stationId) {
        const parsedSingle = schema.shape.moments.element.parse(newSingle);
        if (type === "station") {
          await remoteCall("momentUpdate", { station: stationId, index, moment: parsedSingle });
          const call = await remoteCall("stationList", { station: stationId, forceRenewCache: true });
          if (call.stations && call.stations[0].moments) setItems(call.stations[0].moments);
        } else if (type === "onboardingStation") {
          await remoteCall("onboardingMomentUpdate", { station: stationId, index, moment: parsedSingle });
          const call = await remoteCall("onboardingStationList", { station: stationId });
          if (call.onboardingStations && call.onboardingStations[0].moments) setItems(call.onboardingStations[0].moments);
        }
        
        closeSingle();
      }
    } catch (error) {
      if ((error as Error).name === "ZodError") {
        console.log(error);
      }
      console.log(error);
    }
  }; 

  /* const deleteSingle = async () => {
    if (givenCurrent && givenCurrent._id) {
      const confirm = window.confirm("Do you really want to delete?");
      if (confirm) {
        await remoteCall("pinpointDelete", { _id: givenCurrent._id });
        const call = await remoteCall("pinpointList", {});
        if (call.pinpoints) setItems(call.pinpoints);
        closeSingle();
      }
    }
  }; */

  /* const createNew = () => {
    setGivenCurrent(emptySingle);
  }; */
  
  return <div className="Stations screen">
    <h1>Moments</h1>
    <span className="subtitle">{multiLanguageString(stationName)}</span>
    {/* <div className="actions">
      <button onClick={createNew}>New</button>
    </div> */}
    
    {items 
      && items.map((single, index) => 
        <ListItem 
          key={index} 
          title={`#${index + 1} ${single.type}`} 
          onClick={() => openSingle(single, index)}
        />,
      )}
    
    <Modal closeModal={closeSingle} isOpen={!!givenCurrent} title="Edit moment">
      {givenCurrent && typeof givenCurrentIndex === "number" && characters && medias && winItems && <Form {...{ givenCurrent, givenCurrentIndex, saveSingle, characters, medias, items: winItems }} />}
    </Modal>
  </div>;
};
