import FileResizer from "react-image-file-resizer";

export const resizeImage = (
  { blob, width, height, format, quality }: 
  {
    blob: Blob,
    width: number, 
    height?: number, 
    format?: "image/jpeg" | "image/png" | "image/webp",
    quality?: number,
  },
) => new Promise((resolve: (value: string) => void, reject) => {
  try {
    FileResizer.imageFileResizer(
      blob,
      width,
      height || 99999999,
      format === "image/jpeg" 
        ? "JPEG"
        : format === "image/png"
          ? "PNG"
          : format === "image/webp"
            ? "WEBP"
            : "JPEG",
      quality || 60,
      0,
      (uri) => {
        if (typeof uri === "string") resolve(uri);
      },
      "base64",
    );
  } catch (error) {
    reject(error);
  }
});

