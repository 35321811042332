import { useEffect, useState } from "react";
import { User, remoteCall } from "../../App";

import "./index.scss";
import { stationSchema } from "../../types/station";
import { z } from "zod";
import { multiLanguageString } from "../../utils/multiLanguageString";
import { ListItem } from "../../components/ListItem";
import { useNavigate } from "react-router-dom";

const schema = z.object({
  ...stationSchema.shape,
  nameId: z.string(),
});
type Single = z.infer<typeof schema>;

export const OnboardingStations = (
  { user }: 
  { user: User }) => {
  if (!user.privileges.includes("stations")) return <div>Access denied</div>;

  const [items, setItems] = useState<Single[]>();

  useEffect(() => {
    (async () => {
      try {
        const call = await remoteCall("onboardingStationList", { });
        if (call.onboardingStations) setItems(call.onboardingStations);
      } catch (error) {}
    })();
  }, []);

  const navigate = useNavigate();

  const openSingle = (single: Single) => {
    navigate(`/onboardingStations/${single._id}`);
  };
  
  return <div className="OnboardingStations screen">
    <h1>Onboarding stations</h1>
    
    {items && items.map((single) => 
      <ListItem 
        key={single._id} 
        title={multiLanguageString(single.name)} 
        onClick={() => openSingle(single)}
      />,
    )}
  </div>;
};
