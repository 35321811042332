import { z } from "zod";
import { multiLanguageStringSchema } from "./multiLanguageString";

export const mediaSchema = z.object({
  _id: z.string(),
  analyticsName: z.string().optional(),
  title: multiLanguageStringSchema,
  slides: z.array(z.object({
    type: z.enum(["file", "video", "image", "audio"]),
    fileUrl: multiLanguageStringSchema,
    description: multiLanguageStringSchema.optional(),
    preview: multiLanguageStringSchema.optional(),
  })),
  hideOutsideExperience: z.boolean().optional(),
  relatedLinks: z.array(z.object({
    url: multiLanguageStringSchema,
    text: multiLanguageStringSchema,
    hideOutsideExperience: z.boolean().optional(),
  })).optional(),
});
