import { useRef } from "react";
import { AriaNumberFieldProps, useLocale, useNumberField } from "react-aria";
import { useNumberFieldState } from "react-stately";

import "./index.scss";

export const NumberField = (props: AriaNumberFieldProps & { className?: string }) => {
  const { label } = props;
  const { locale } = useLocale();
  const state = useNumberFieldState({ ...props, locale });

  const ref = useRef(null);
  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useNumberField(props, state, ref);

  return (
    <div className={`NumberField ${props.className || ""} ${props.isReadOnly ? "isReadonly" : ""}`}>
      <label {...labelProps}>{label}</label>
      <input {...inputProps} ref={ref} />
      {props.description && (
        <div {...descriptionProps} className="description">
          {props.description}
        </div>
      )}
      {props.errorMessage && (
        <div {...errorMessageProps} className="errorMessage">
          {props.errorMessage}
        </div>
      )}
    </div>
  );
};
