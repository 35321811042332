import { z } from "zod";
import { RepeatingFields } from "../RepeatingFields";
import { multiLanguageStringSchema } from "../../types/multiLanguageString";
import { SelectionBox } from "../ListBox";
import { Selection as StatelySelection } from "react-stately";

import "./index.scss";
import { Base64ImageField } from "../Base64ImageField";

type RasterResizeOptions = { 
  width: number, 
  height?: number, 
  quality?: number, 
};

type Base64ImageFieldProps = {
  format: "image/jpeg",
  rasterResizeOptions: RasterResizeOptions,
} | {
  format: "image/png",
  rasterResizeOptions: RasterResizeOptions
} | {
  format: "image/webp",
  rasterResizeOptions: RasterResizeOptions
} | {
  format: "image/svg+xml",
  svgResizeOptions?: {}
};

export const MultiLanguageBase64ImageField = (
  { property, items, label, readonly, description, addItem, removeItem, parseSingleSelectionChange, parseTextChange, ...props }: 
  { 
    property: string, 
    items: z.infer<typeof multiLanguageStringSchema>,
    label: string,
    readonly?: boolean,
    description?: string,
    addItem: <Key extends string>(key: Key, emptyItem: (z.infer<typeof multiLanguageStringSchema>[number])) => void,
    removeItem: (key: string, index: number) => void,
    parseSingleSelectionChange: (key: string) => (values: StatelySelection) => void,
    parseTextChange: (key: string) => (value: string) => void
  } & Base64ImageFieldProps,
) => {
  return <RepeatingFields
    className="MultiLanguageTextField"
    label={label || ""}
    readonly={readonly}
    addItemFunction={() => {addItem(property, { lang: items.some((item) => item.lang === "it") ? "en" : "it", string: "" });}}
    removeItemFunction={(index: number) => {removeItem(property, index);}}
    items={items}
    itemElement={(item, index) => <>
      <SelectionBox label="Language" selectionMode="single" defaultSelectedKeys={[item.lang]} onSelectionChange={parseSingleSelectionChange(`${property}.${index}.lang`)} hideBox items={[{ label: "Italian", value: "it" }, { label: "English", value: "en" }]} />
      {props.format === "image/svg+xml"
        ? <Base64ImageField description={description} label="Text" value={item.string} onChange={parseTextChange(`${property}.${index}.string`)} format={props.format} svgResizeOptions={props.svgResizeOptions} />
        : <Base64ImageField description={description} label="Text" value={item.string} onChange={parseTextChange(`${property}.${index}.string`)} format={props.format} rasterResizeOptions={props.rasterResizeOptions}  />
      }
    </>}
  />;
};
