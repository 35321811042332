import { z } from "zod";
import { multiLanguageStringSchema } from "./multiLanguageString";

const dialogueSchema = z.object({
  type: z.literal("dialogue"),
  characterId: z.string(),
  moodShortName: z.string(),
  dialogueText: multiLanguageStringSchema,
});

const itemGivenSchema = z.object({
  type: z.literal("itemGiven"),
  characterId: z.string().optional(),
  dialogueText: multiLanguageStringSchema.optional(),
  itemId: z.string(),
});

const skinGivenSchema = z.object({
  type: z.literal("skinGiven"),
  characterId: z.string().optional(),
  dialogueText: multiLanguageStringSchema.optional(),
  skin: z.string(),
});

const partialQuestItemSchema = z.object({
  type: z.literal("partialQuestItem"),
  name: multiLanguageStringSchema,
  image: multiLanguageStringSchema,
});

export const additionalMediaSchema = z.object({
  type: z.literal("additionalMedia"),
  characterId: z.string().optional(),
  dialogueText: multiLanguageStringSchema.optional(),
  medias: z.array(z.object({
    media: z.string(),
    itemGiven: itemGivenSchema.omit({ type: true }).extend({ hideItem: z.boolean().optional() }).optional(),
  })),
});

const imageTapGameSchema = z.object({
  type: z.literal("imageTapGame"),
  analyticsName: z.string().optional(),
  steps: z.object({
    characterId: z.string().optional(),
    dialogueText: multiLanguageStringSchema.optional(),
    image: multiLanguageStringSchema.optional(),
    video: multiLanguageStringSchema.optional(),
    customRightIcon: multiLanguageStringSchema.optional(),
    customWrongIcon: multiLanguageStringSchema.optional(),
    justOne: z.boolean().optional(),
    clickableMap: z.array(
      z.object({
        top: z.number(),
        left: z.number(),
        width: z.number(),
        height: z.number(),
      }),
    ),
  }).array(),
  congratulate: z.boolean().optional(),
});

const orderingGameSchema = z.object({
  type: z.literal("orderingGame"),
  analyticsName: z.string().optional(),
  characterId: z.string().optional(),
  dialogueText: multiLanguageStringSchema.optional(),
  steps: z.array(
    z.discriminatedUnion("stepType", [
      z.object({
        stepType: z.literal("text"),
        text: multiLanguageStringSchema,
        placeholderLabel: multiLanguageStringSchema.optional(),
      }),
    ]),
  ),
  congratulate: z.boolean().optional(),
});

const multipleChoiceGameSchema = z.object({
  type: z.literal("multipleChoiceGame"),
  analyticsName: z.string().optional(),
  questions: z.array(z.object({
    characterId: z.string().optional(),
    dialogueText: multiLanguageStringSchema.optional(),
    image: multiLanguageStringSchema.optional(),
    hideShadow: z.boolean().optional(),
    alternativeText: multiLanguageStringSchema.optional(),
    answerFormat: z.enum(["solid", "light"]),
    answers: z.array(z.object({
      text: multiLanguageStringSchema.optional(),
      image: multiLanguageStringSchema.optional(),
      right: z.boolean().optional(),
    })),
  })),
  congratulate: z.boolean().optional(),
  atLeastOneRight: z.boolean().optional(),
});

export const stationSchema = z.object({
  _id: z.string(),
  analyticsName: z.string().optional(),
  museumId: z.string().optional(),
  initial: z.boolean().optional(),
  final: z.boolean().optional(),
  image: multiLanguageStringSchema.optional().nullable(),
  name: multiLanguageStringSchema.optional(),
  code: z.string().optional(),
  moments: z.array(
    z.discriminatedUnion("type", [
      dialogueSchema, //a piece of dialogue
      itemGivenSchema, //a collection item is awarded
      skinGivenSchema, //a skin is awarded
      partialQuestItemSchema, //a piece of the final museum artifact awarded
      additionalMediaSchema, //one or more additional media - some may award collection items
      imageTapGameSchema, //tap correct point(s) on image
      orderingGameSchema, //put the given answers in order
      multipleChoiceGameSchema, //multiple choice game (may contain many subsequent questions)
    ]),
  ),
  nextStationId: z.string().optional(),
});
