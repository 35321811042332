import { z } from "zod";
import { RepeatingFields } from "../RepeatingFields";
import { multiLanguageStringSchema } from "../../types/multiLanguageString";
import { SelectionBox } from "../ListBox";
import { Selection as StatelySelection } from "react-stately";
import { RichTextField } from "../RichTextField";

import "./index.scss";

export const MultiLanguageRichTextField = (
  { property, items, label, addItem, removeItem, parseSingleSelectionChange, parseTextChange }: 
  { 
    property: string, 
    items: z.infer<typeof multiLanguageStringSchema>,
    label: string,
    addItem: <Key extends string>(key: Key, emptyItem: (z.infer<typeof multiLanguageStringSchema>[number])) => void,
    removeItem: (key: string, index: number) => void,
    parseSingleSelectionChange: (key: string) => (values: StatelySelection) => void,
    parseTextChange: (key: string) => (value: string) => void
  },
) => {
  return <RepeatingFields
    className="MultiLanguageRichTextField"
    label={label || ""}
    addItemFunction={() => {addItem(property, { lang: items.some((item) => item.lang === "it") ? "en" : "it", string: "" });}}
    removeItemFunction={(index: number) => {removeItem(property, index);}}
    items={items}
    itemElement={(item, index) => <>
      <SelectionBox label="Language" selectionMode="single" defaultSelectedKeys={[item.lang]} onSelectionChange={parseSingleSelectionChange(`${property}.${index}.lang`)} hideBox items={[{ label: "Italian", value: "it" }, { label: "English", value: "en" }]} />
      <RichTextField label="Text" value={item.string} onChange={parseTextChange(`${property}.${index}.string`)}  />
    </>}
  />;
};
