import { ChangeEvent, useRef } from "react";
import { resizeImage } from "../../utils/resizeImage";

import "./index.scss";
import { getBase64 } from "../../utils/getBase64";
import { remoteCall } from "../../App";

type RasterResizeOptions = { 
  width: number, 
  height?: number, 
  quality?: number, 
};

type Base64ImageFieldProps = {
  label?: string,
  value: string | undefined,
  description?: string,
  format: "image/jpeg",
  rasterResizeOptions: RasterResizeOptions,
  onChange: (newUrl: string) => void,
} | {
  label?: string,
  value: string | undefined,
  description?: string,
  format: "image/png",
  rasterResizeOptions: RasterResizeOptions
  onChange: (newUrl: string) => void,
} | {
  label?: string,
  value: string | undefined,
  description?: string,
  format: "image/webp",
  rasterResizeOptions: RasterResizeOptions
  onChange: (newUrl: string) => void,
} | {
  label?: string,
  value: string | undefined,
  description?: string,
  format: "image/svg+xml",
  svgResizeOptions?: {}
  onChange: (newUrl: string) => void,
};

export const Base64ImageField = (
  { ...props }: Base64ImageFieldProps) => {
  const { label, value, format, onChange } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    
    const file = event.target.files[0];
    
    if (file.type !== format) return;

    if (format === "image/jpeg" || format === "image/png" || format === "image/webp") {
      const base64image = await resizeImage({ blob: file, format, ...props.rasterResizeOptions });
      onChange(base64image);
    } else if (format === "image/svg+xml") {
      const base64image = await getBase64(file);
      let optimizedImage = base64image;

      try {
        const call = await remoteCall("svgOptimize", { svg: base64image });
        optimizedImage = call.optimizedSvg;
      } catch (error) {}

      onChange(optimizedImage);
    }
  };

  return <div className="Base64ImageField">
    <label>{label || ""}</label>
    {value && <div className="preview">
      <img alt="" src={value} />
    </div>}
    <input ref={inputRef} accept={format} type="file" onChange={handleFile} />
    {props.description && (
      <div className="description">
        {props.description}
      </div>
    )}
  </div>;
};
