import { z } from "zod";
import { multiLanguageStringSchema } from "./multiLanguageString";

export const museumSchema = z.object({
  _id: z.string(),
  analyticsName: z.string().optional(),
  name: multiLanguageStringSchema,
  humanAddress: multiLanguageStringSchema,
  photo: z.string().optional(),
  experiencePhoto: z.string().optional(),
  mapPin: z.string(),
  visitedMapPin: z.string(),
  contacts: z.array(z.object({
    type: z.enum(["WEBSITE", "FB", "IG", "YT", "LN"]),
    url: z.string(),
  })).optional(),
  ticketURL: multiLanguageStringSchema,
  infoText: multiLanguageStringSchema.optional(),
  position: z.object({
    lat: z.number(),
    lng: z.number(),
  }),
});
