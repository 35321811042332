import { z } from "zod";
import { multiLanguageStringSchema } from "./multiLanguageString";

export const itemSchema = z.object({
  _id: z.string(),
  analyticsName: z.string().optional(),
  name: multiLanguageStringSchema,
  image: z.string(),
  museums: z.string().array(),
  positions: z.number().array(),
  medias: z.string().array(),
});
