import { useEffect, useState } from "react";
import { User, remoteCall } from "../../App";
import cloneDeep from "clone-deep";

import "./index.scss";
import { characterSchema as schema } from "../../types/character";
import { z } from "zod";
import { multiLanguageString } from "../../utils/multiLanguageString";
import { ListItem } from "../../components/ListItem";
import { Modal } from "../../components/Modal";
import { TextField } from "../../components/TextField";
import { changeHandlers } from "../../utils/changeHandlers";
import { MultiLanguageTextField } from "../../components/MultiLanguageTextField";
import { Base64ImageField } from "../../components/Base64ImageField";
import { RepeatingFields } from "../../components/RepeatingFields";

type Single = z.infer<typeof schema>;

/* const emptySingle: Single = {
  _id: "",
  name: [],
  position: {
    lat: 0,
    lng: 0,
  },
  medias: [],
}; */

const Form = ({ givenCurrent, saveSingle /* deleteSingle */ }: { givenCurrent: Single, saveSingle: (newSingle: Single) => void, /* deleteSingle: () => {} */ }) => {
  const [current, setCurrent] = useState(cloneDeep(givenCurrent));

  const { parseTextChange, parseSingleSelectionChange, addItem, removeItem } = changeHandlers(setCurrent);

  return <div className="form">
    {current._id !== "" && <>
      <TextField label="ID" value={current._id} onChange={parseTextChange("_id")} isReadOnly />
      <hr/>
    </>}

    <MultiLanguageTextField 
      label="Name"
      property="name"
      items={current.name}
      {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
    />

    <RepeatingFields
      label="Moods"
      items={current.moods}
      disableAdd
      disableDelete
      itemElement={(item, index) => <>
        <TextField 
          label="Shortname"
          value={item.shortName}
          onChange={parseTextChange(`moods.${index}.shortName`)}
          isReadOnly
        />
        <Base64ImageField 
          label="Image"
          value={item.image}
          format="image/png"
          description="Squared photo. PNG. Min width 800px."
          rasterResizeOptions={{ width: 800 }}
          onChange={parseTextChange(`moods.${index}.image`)}
        />
      </>}
      addItemFunction={() => {addItem("moods", { image: "", shortName: "" });}}
      removeItemFunction={(index: number) => {removeItem("moods", index);}}
    />

    <div className="modalActions">
      <button onClick={() => {saveSingle(current);}}>Save</button>
      {/* {current._id !== "" && <button onClick={() => {deleteSingle();}}>Delete</button>} */}
    </div>
  </div>;
};

export const Characters = (
  { user }: 
  { user: User }) => {
  if (!user.privileges.includes("characters")) return <div>Access denied</div>;

  const [items, setItems] = useState<Single[]>();
  
  const [givenCurrent, setGivenCurrent] = useState<Single | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const call = await remoteCall("characterList", {});
        if (call.characters) setItems(call.characters);
      } catch (error) {}
    })();
  }, []);

  const openSingle = (single: Single) => {
    setGivenCurrent(single);
  };

  const closeSingle = () => {
    setGivenCurrent(undefined);
  };

  const saveSingle = async (newSingle: Single) => {
    try {
      const parsedSingle = schema.parse(newSingle);
      await remoteCall("characterUpdate", parsedSingle);
      const call = await remoteCall("characterList", { forceRenewCache: true });
      if (call.characters) setItems(call.characters);
      closeSingle();
    } catch (error) {
      if ((error as Error).name === "ZodError") {
        console.log(error);
      }
      console.log(error);
    }
  }; 

  /* const deleteSingle = async () => {
    if (givenCurrent && givenCurrent._id) {
      const confirm = window.confirm("Do you really want to delete?");
      if (confirm) {
        await remoteCall("pinpointDelete", { _id: givenCurrent._id });
        const call = await remoteCall("pinpointList", {});
        if (call.pinpoints) setItems(call.pinpoints);
        closeSingle();
      }
    }
  }; */

  /* const createNew = () => {
    setGivenCurrent(emptySingle);
  }; */
  
  return <div className="Characters screen">
    <h1>Characters</h1>
    {/* <div className="actions">
      <button onClick={createNew}>New</button>
    </div> */}
    
    {items 
      && items.map((single) => 
        <ListItem 
          key={single._id} 
          title={multiLanguageString(single.name)} 
          onClick={() => openSingle(single)}
        />,
      )}
    
    <Modal closeModal={closeSingle} isOpen={!!givenCurrent} title="Edit character">
      {givenCurrent && <Form {...{ givenCurrent, saveSingle }} />}
    </Modal>
  </div>;
};
