import { useEffect, useState } from "react";
import { User, remoteCall } from "../../App";
import { convertArrayToCSV } from "convert-array-to-csv";

import "./index.scss";
import cloneDeep from "clone-deep";

export const Users = (
  { user }: 
  { user: User }) => {
  if (!user.privileges.includes("userListing")) return <div>Access denied</div>;

  const [items, setItems] = useState<User[]>();

  const downloadList = (newsletterOnly: boolean) => {
    if (items) {
      const exportable = cloneDeep(items).filter((single) => {
        if (newsletterOnly) {
          return single.newsletter === true;
        }

        return true;
      }).map((single) => ({
        _id: single._id,
        familyName: single.familyName,
        givenName: single.givenName,
        displayName: single.displayName,
        email: single.email,
        age: single.age,
        policy: single.policy,
        newsletter: single.newsletter,
      }));
      
      const csv = convertArrayToCSV(exportable);
      
      const csvContent = "data:text/csv;charset=utf-8," + csv;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", newsletterOnly ? "newsletterUsers.csv" : "users.csv");
      document.body.appendChild(link); // Required for FF
      
      link.click();
    }
  };
  
  useEffect(() => {
    (async () => {
      try {
        const call = await remoteCall("userList", {});
        if (call.users) setItems(call.users);
      } catch (error) {}
    })();
  }, []);
  
  return <div className="Users screen">
    <h1>Users</h1>
    
    <button onClick={() => {downloadList(true);}}>Export only newsletter allowed</button>
    <button onClick={() => {downloadList(false);}}>Export all</button>
    
  </div>;
};
