import { useToggleState } from "react-stately";
import { AriaToggleButtonProps, useToggleButton } from "react-aria";
import { useRef } from "react";

import "./index.scss";

export const ToggleField = (props: AriaToggleButtonProps & { label?: string, isReadOnly?: boolean }) => {
  const ref = useRef(null);
  const state = useToggleState(props);
  const { buttonProps } = useToggleButton(props, state, ref);

  return (
    <div className="ToggleField">
      <label>
        <button
          {...buttonProps}
          disabled={props.isReadOnly}
          className={`${state.isSelected ? "selected" : ""}`}
          ref={ref}
        >
          <span />
        </button>
        <span className="labelText">{props.label || ""} {props.isReadOnly && "🔒"}</span>
      </label>
    </div>
  );
};
