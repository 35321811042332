import { useEffect, useState } from "react";
import { User, remoteCall } from "../../App";
import cloneDeep from "clone-deep";

import "./index.scss";
import { museumSchema as schema } from "../../types/museum";
import { z } from "zod";
import { multiLanguageString } from "../../utils/multiLanguageString";
import { ListItem } from "../../components/ListItem";
import { Modal } from "../../components/Modal";
import { TextField } from "../../components/TextField";
import { changeHandlers } from "../../utils/changeHandlers";
import { MultiLanguageTextField } from "../../components/MultiLanguageTextField";
import { SelectionBox } from "../../components/ListBox";
import { MultiLanguageRichTextField } from "../../components/MultiLanguageRichTextField";
import { Base64ImageField } from "../../components/Base64ImageField";
import { MapField } from "../../components/MapField";
import { RepeatingFields } from "../../components/RepeatingFields";
import { Link } from "react-router-dom";

type Single = z.infer<typeof schema>;

/* const emptySingle: Single = {
  _id: "",
  name: [],
  position: {
    lat: 0,
    lng: 0,
  },
  medias: [],
}; */

const Form = ({ givenCurrent, saveSingle, showStationsLink /* deleteSingle */ }: { givenCurrent: Single, saveSingle: (newSingle: Single) => void, showStationsLink: boolean, /* deleteSingle: () => {} */ }) => {
  const [current, setCurrent] = useState(cloneDeep(givenCurrent));

  const { parseTextChange, parseSingleSelectionChange, parsePositionChange, addItem, removeItem } = changeHandlers(setCurrent);

  return <div className="form">
    {showStationsLink && <Link className="furtherLink" to={`/museums/${current._id}/stations`}>Browse stations</Link>}

    {current._id !== "" && <>
      <TextField label="ID" value={current._id} onChange={parseTextChange("_id")} isReadOnly />
      <hr/>
    </>}

    <TextField label="Analytics name" value={current.analyticsName} onChange={parseTextChange("analyticsName")} />

    <MultiLanguageTextField 
      label="Name"
      property="name"
      items={current.name}
      {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
    />

    <MultiLanguageTextField 
      label="Human address"
      property="humanAddress"
      items={current.humanAddress}
      {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
    />

    <Base64ImageField 
      label="Photo"
      value={current.photo || ""}
      format="image/png"
      description="Squared photo. PNG. Min width 500px."
      rasterResizeOptions={{ width: 500 }}
      onChange={parseTextChange("photo")}
    />

    <Base64ImageField 
      label="Experience photo"
      value={current.experiencePhoto || ""}
      format="image/png"
      description="Squared photo. PNG. Min width 600px."
      rasterResizeOptions={{ width: 600 }}
      onChange={parseTextChange("experiencePhoto")}
    />

    <Base64ImageField 
      label="Map Pin"
      value={current.mapPin || ""}
      format="image/svg+xml"
      description="Upload only SVG."
      onChange={parseTextChange("mapPin")}
    />

    <Base64ImageField 
      label="Map Pin"
      value={current.visitedMapPin || ""}
      format="image/svg+xml"
      description="Upload only SVG."
      onChange={parseTextChange("visitedMapPin")}
    />

    <RepeatingFields
      label="Contacts"
      items={current.contacts || []}
      itemElement={(item, index) => <>
        <SelectionBox
          label="Type"
          selectionMode="single"
          defaultSelectedKeys={[item.type] || []}
          onSelectionChange={parseSingleSelectionChange(`contacts.${index}.type`)}
          items={[{ label: "Website", value: "WEBSITE" }, { label: "Facebook", value: "FB" }, { label: "Instagram", value: "IG" }, { label: "Youtube", value: "YT" }, { label: "LinkedIn", value: "LN" }]}
        />
        <TextField 
          value={item.url}
          onChange={parseTextChange(`contacts.${index}.url`)}
        />
      </>}
      //@ts-expect-error
      addItemFunction={() => {addItem("contacts", { type: "WEBSITE", url: "" });}}
      removeItemFunction={(index: number) => {removeItem("contacts", index);}}
    />

    <MultiLanguageTextField 
      label="Ticket URL"
      property="ticketURL"
      items={current.ticketURL || []}
      {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
    />

    <MultiLanguageRichTextField 
      label="Info text"
      property="infoText"
      items={current.infoText || []}
      {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
    />

    <MapField label="Position" position={current.position} onChange={parsePositionChange("position")} />

    <div className="modalActions">
      <button onClick={() => {saveSingle(current);}}>Save</button>
      {/* {current._id !== "" && <button onClick={() => {deleteSingle();}}>Delete</button>} */}
    </div>
  </div>;
};

export const Museums = (
  { user }: 
  { user: User }) => {
  if (!user.privileges.includes("museums")) return <div>Access denied</div>;

  const [items, setItems] = useState<Single[]>();
  
  const [givenCurrent, setGivenCurrent] = useState<Single | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const call = await remoteCall("museumList", {});
        if (call.museums) setItems(call.museums);
      } catch (error) {}
    })();
  }, []);

  const openSingle = (single: Single) => {
    setGivenCurrent(single);
  };

  const closeSingle = () => {
    setGivenCurrent(undefined);
  };

  const saveSingle = async (newSingle: Single) => {
    try {
      const parsedSingle = schema.parse(newSingle);
      await remoteCall("museumUpdate", parsedSingle);
      const call = await remoteCall("museumList", {});
      if (call.museums) setItems(call.museums);
      closeSingle();
    } catch (error) {
      if ((error as Error).name === "ZodError") {
        console.log(error);
      }
      console.log(error);
    }
  }; 

  /* const deleteSingle = async () => {
    if (givenCurrent && givenCurrent._id) {
      const confirm = window.confirm("Do you really want to delete?");
      if (confirm) {
        await remoteCall("pinpointDelete", { _id: givenCurrent._id });
        const call = await remoteCall("pinpointList", {});
        if (call.pinpoints) setItems(call.pinpoints);
        closeSingle();
      }
    }
  }; */

  /* const createNew = () => {
    setGivenCurrent(emptySingle);
  }; */
  
  return <div className="Museums screen">
    <h1>Museums</h1>
    {/* <div className="actions">
      <button onClick={createNew}>New</button>
    </div> */}
    
    {items 
      && items.map((single) => 
        <ListItem 
          key={single._id} 
          title={multiLanguageString(single.name)} 
          onClick={() => openSingle(single)}
        />,
      )}
    
    <Modal closeModal={closeSingle} isOpen={!!givenCurrent} title="Edit museum">
      {givenCurrent && <Form showStationsLink={user.privileges.includes("stations")} {...{ givenCurrent, saveSingle }} />}
    </Modal>
  </div>;
};
