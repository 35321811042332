import { useEffect, useState } from "react";
import { User, remoteCall } from "../../App";
import cloneDeep from "clone-deep";

import "./index.scss";
import { itemSchema as originalSchema } from "../../types/item";
import { z } from "zod";
import { multiLanguageString } from "../../utils/multiLanguageString";
import { ListItem } from "../../components/ListItem";
import { Modal } from "../../components/Modal";
import { TextField } from "../../components/TextField";
import { changeHandlers } from "../../utils/changeHandlers";
import { MultiLanguageTextField } from "../../components/MultiLanguageTextField";
import { SelectionBox } from "../../components/ListBox";
import { Base64ImageField } from "../../components/Base64ImageField";
import { museumSchema } from "../../types/museum";

const schema = originalSchema.omit({ medias: true });
type Single = z.infer<typeof schema>;
type Museum = z.infer<typeof museumSchema>;

/* const emptySingle: Single = {
  _id: "",
  name: [],
  position: {
    lat: 0,
    lng: 0,
  },
  medias: [],
}; */

const Form = ({ givenCurrent, saveSingle, museums /* deleteSingle */ }: { givenCurrent: Single, saveSingle: (newSingle: Single) => void, museums: Museum[], /* deleteSingle: () => {} */ }) => {
  const [current, setCurrent] = useState(cloneDeep(givenCurrent));

  const { parseTextChange, parseSingleSelectionChange, parseMultipleSelectionChange, addItem, removeItem } = changeHandlers(setCurrent);

  return <div className="form">
    {current._id !== "" && <>
      <TextField label="ID" value={current._id} onChange={parseTextChange("_id")} isReadOnly />
      <hr/>
    </>}

    <TextField label="Analytics name" value={current.analyticsName} onChange={parseTextChange("analyticsName")} />

    <MultiLanguageTextField 
      label="Name"
      property="name"
      items={current.name}
      {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
    />

    <Base64ImageField 
      label="Image"
      value={current.image || ""}
      format="image/png"
      description="Transparent squared image. PNG. Min width 800px."
      rasterResizeOptions={{ width: 800 }}
      onChange={parseTextChange("image")}
    />

    <SelectionBox 
      label="Museums"
      selectionMode="multiple" 
      defaultSelectedKeys={current.museums || []} 
      onSelectionChange={parseMultipleSelectionChange("museums")} 
      items={museums.map((museum) => ({ value: museum._id, label: multiLanguageString(museum.name) }))}
    />

    <SelectionBox 
      label="Positions"
      selectionMode="multiple" 
      defaultSelectedKeys={current.positions.map((single) => single.toString()) || []} 
      onSelectionChange={(newStringList) => {
        parseMultipleSelectionChange("positions")(Array.from(newStringList).map((string) => parseInt(string.toString())));
      }} 
      items={
        [
          {
            label: "Small frame 1",
            value: 0,
          },
          {
            label: "Small frame 2",
            value: 1,
          },
          {
            label: "Small frame 3",
            value: 2,
          },
          {
            label: "Small frame 4",
            value: 3,
          },
          {
            label: "Large central frame",
            value: 4,
          },
          {
            label: "Display case 1",
            value: 5,
          },
          {
            label: "Display case 2",
            value: 6,
          },
          {
            label: "Display case 3",
            value: 7,
          },
          {
            label: "Display case 4",
            value: 8,
          },
          {
            label: "Table 1",
            value: 9,
          },
          {
            label: "Table 2",
            value: 10,
          },
          {
            label: "Table 3",
            value: 11,
          },
        ].map((singleRow) => ({ ...singleRow, value: singleRow.value.toString() }))
      }
    />

    <div className="modalActions">
      <button onClick={() => {saveSingle(current);}}>Save</button>
      {/* {current._id !== "" && <button onClick={() => {deleteSingle();}}>Delete</button>} */}
    </div>
  </div>;
};

export const Items = (
  { user }: 
  { user: User }) => {
  if (!user.privileges.includes("items")) return <div>Access denied</div>;

  const [items, setItems] = useState<Single[]>();
  const [museums, setMuseums] = useState<Museum[]>();
  
  const [givenCurrent, setGivenCurrent] = useState<Single | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const museumCall = await remoteCall("museumList", {});
        if (museumCall.museums) setMuseums(museumCall.museums);

        const call = await remoteCall("itemList", {});
        if (call.items) setItems(call.items);
      } catch (error) {}
    })();
  }, []);

  const openSingle = (single: Single) => {
    setGivenCurrent(single);
  };

  const closeSingle = () => {
    setGivenCurrent(undefined);
  };

  const saveSingle = async (newSingle: Single) => {
    try {
      const parsedSingle = schema.parse(newSingle);
      await remoteCall("itemUpdate", parsedSingle);
      const call = await remoteCall("itemList", { forceRenewCache: true });
      if (call.items) setItems(call.items);
      closeSingle();
    } catch (error) {
      if ((error as Error).name === "ZodError") {
        console.log(error);
      }
      console.log(error);
    }
  }; 

  /* const deleteSingle = async () => {
    if (givenCurrent && givenCurrent._id) {
      const confirm = window.confirm("Do you really want to delete?");
      if (confirm) {
        await remoteCall("pinpointDelete", { _id: givenCurrent._id });
        const call = await remoteCall("pinpointList", {});
        if (call.pinpoints) setItems(call.pinpoints);
        closeSingle();
      }
    }
  }; */

  /* const createNew = () => {
    setGivenCurrent(emptySingle);
  }; */
  
  return <div className="Items screen">
    <h1>Items</h1>
    {/* <div className="actions">
      <button onClick={createNew}>New</button>
    </div> */}
    
    {items 
      && items.map((single) => 
        <ListItem 
          key={single._id} 
          title={multiLanguageString(single.name)} 
          onClick={() => openSingle(single)}
        />,
      )}
    
    <Modal closeModal={closeSingle} isOpen={!!givenCurrent} title="Edit item">
      {givenCurrent && museums && <Form {...{ givenCurrent, saveSingle, museums }} />}
    </Modal>
  </div>;
};
