import { EditorState/* , RichUtils */ } from "draft-js";
import { convertFromHTML, convertToHTML } from "draft-convert";
import Editor from "@draft-js-plugins/editor";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import createLinkPlugin from "@draft-js-plugins/anchor";
import { useRef, useState } from "react";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import "@draft-js-plugins/anchor/lib/plugin.css";

import "./index.scss";

import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  /* UnorderedListButton,
  OrderedListButton, */
} from "@draft-js-plugins/buttons";

export const RichTextField = ({ value, onChange, id, label }: { value: string, onChange: (value: string) => void, id?: string, label: string }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      convertFromHTML({
        htmlToEntity: (nodeName, node, createEntity) => {
          if (nodeName === "a") {
            return createEntity("LINK", "MUTABLE", { url: node.href });
          }
        },
      })(value || ""),
    ),
  );

  const [{ plugins, Toolbar, LinkButton }] = useState(() => {
    const toolbarPlugin = createToolbarPlugin();
    const linkPlugin = createLinkPlugin();
    const { Toolbar: ToolbarPlugin } = toolbarPlugin;
    const { LinkButton: LinkButtonPlugin } = linkPlugin;
    const currentPlugins = [toolbarPlugin, linkPlugin];
    return {
      plugins: currentPlugins,
      Toolbar: ToolbarPlugin,
      LinkButton: LinkButtonPlugin,
    };
  });

  const onDraftChange = (changedEditorState: EditorState) => {
    setEditorState(changedEditorState);
    onChange(convertToHTML(changedEditorState.getCurrentContent()) === "<p></p>"
      ? ""
      : convertToHTML({
        entityToHTML: (entity, originalText) => {
          if (entity.type === "LINK") {
            return <a href={entity.data.url}>{originalText}</a>;
          }
          return originalText;
        },
      })(changedEditorState.getCurrentContent()),
    );
  };

  /*   const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      onChange({
        target: {
          name: id,
          value:
            convertToHTML(editorState.getCurrentContent()) === "<p></p>"
              ? ""
              : convertToHTML({
                  entityToHTML: (entity, originalText) => {
                    if (entity.type === "LINK") {
                      return <a href={entity.data.url}>{originalText}</a>;
                    }
                    return originalText;
                  },
                })(editorState.getCurrentContent()),
        },
      });
    }
  }; */

  const editorRef = useRef<Editor>(null);

  return (
    <div
      className={`RichTextField ${
        convertToHTML(editorState.getCurrentContent()).replace("<p></p>", "")
          ? "filled"
          : ""
      }`}
    >
      <label htmlFor={id}>{label}</label>
      <div
        className="input"
        id={id}
        onClick={() => editorRef.current && editorRef.current.focus()}
      >
        <Editor
          editorState={editorState}
          /* onClick={() => editorRef.current && editorRef.current.focus()} */
          onChange={onDraftChange}
          plugins={plugins}
          /* handleKeyCommand={handleKeyCommand} */
          ref={editorRef}
        />
        <div className="toolbarWrapper">
          <Toolbar>
            {(externalProps) => (
              <div>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                {/* <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} /> */}
                <LinkButton {...externalProps} />
              </div>
            )}
          </Toolbar>
        </div>
      </div>
      {/* {errors && errors.length && (
        <div className="errors">
          {errors.map((error) => {
            return (
              <div key={generateRandomHex(6)} className="error">
                {error}
              </div>
            );
          })}
        </div>
      )} */}
    </div>
  );
};
