import { z } from "zod";
import { RepeatingFields } from "../RepeatingFields";
import { multiLanguageStringSchema } from "../../types/multiLanguageString";
import { SelectionBox } from "../ListBox";
import { TextField } from "../TextField";
import { Selection as StatelySelection } from "react-stately";

import "./index.scss";

export const MultiLanguageTextField = (
  { property, items, label, readonly, addItem, removeItem, parseSingleSelectionChange, parseTextChange }: 
  { 
    property: string, 
    items: z.infer<typeof multiLanguageStringSchema>,
    label: string,
    readonly?: boolean,
    addItem: <Key extends string>(key: Key, emptyItem: (z.infer<typeof multiLanguageStringSchema>[number])) => void,
    removeItem: (key: string, index: number) => void,
    parseSingleSelectionChange: (key: string) => (values: StatelySelection) => void,
    parseTextChange: (key: string) => (value: string) => void
  },
) => {
  return <RepeatingFields
    className="MultiLanguageTextField"
    label={label || ""}
    readonly={readonly}
    addItemFunction={() => {
      const lang = items.some((item) => item.lang === "it") ? "en" : "it";
      //const string = (lang === "en" && items.find((item) => item.lang === "it")?.string) ? items.find((item) => item.lang === "it")?.string || "" : "";
      addItem(property, { lang, string: "" });
    }}
    removeItemFunction={(index: number) => {removeItem(property, index);}}
    items={items}
    itemElement={(item, index) => <>
      <SelectionBox label="Language" selectionMode="single" defaultSelectedKeys={[item.lang]} onSelectionChange={parseSingleSelectionChange(`${property}.${index}.lang`)} hideBox items={[{ label: "Italian", value: "it" }, { label: "English", value: "en" }]} />
      <TextField label="Text" value={item.string} onChange={parseTextChange(`${property}.${index}.string`)}  />
    </>}
  />;
};
