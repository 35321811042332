import { useEffect, useState } from "react";
import { User, remoteCall } from "../../App";

import "./index.scss";
import { z } from "zod";
import { changeHandlers } from "../../utils/changeHandlers";
import { multiLanguageStringSchema } from "../../types/multiLanguageString";
import { MultiLanguageTextField } from "../../components/MultiLanguageTextField";
import { MultiLanguageRichTextField } from "../../components/MultiLanguageRichTextField";
import cloneDeep from "clone-deep";

type Single = z.infer<typeof multiLanguageStringSchema>;

const SingleForm = ({ givenCurrent, givenKey, saveSingle }: { givenCurrent: Single, givenKey: string, saveSingle: (key: string, multi: Single) => void }) => {
  const [current, setCurrent] = useState<{ multi: Single }>({ multi: cloneDeep(givenCurrent)  });
  const [changes, setChanges] = useState<boolean>(false);

  const { parseTextChange, parseSingleSelectionChange, addItem, removeItem } = changeHandlers(setCurrent);

  useEffect(() => {
    setChanges(() => {
      console.log((current.multi[0] || {}).lang, (givenCurrent[0] || {}).lang);
      if (
        (current.multi[0] || {}).lang !== (givenCurrent[0] || {}).lang ||
        (current.multi[0] || {}).string !== (givenCurrent[0] || {}).string ||
        (current.multi[1] || {}).lang !== (givenCurrent[1] || {}).lang ||
        (current.multi[1] || {}).string !== (givenCurrent[1] || {}).string
      ) {
        return true;
      }
      return false;
    });
  }, [current, givenCurrent]);

  return <>
    {current && (givenKey.includes("richtext") 
      ? <MultiLanguageRichTextField
        label={givenKey}
        items={current.multi}
        property={"multi"}
        {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}
      />
      : <MultiLanguageTextField 
        label={givenKey}
        items={current.multi}
        property={"multi"}
        {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}
      />)}
    {changes && <div className="saveSingle"><button onClick={() => {saveSingle(givenKey, current.multi);}}>SAVE</button></div>}
  </>;
};

export const Strings = (
  { user }: 
  { user: User }) => {
  if (!user.privileges.includes("strings")) return <div>Access denied</div>;

  const [items, setItems] = useState<{ [key: string]: Single }>({});
  
  useEffect(() => {
    (async () => {
      try {
        const call = await remoteCall("stringList", {});
        if (call.strings) setItems(call.strings);
      } catch (error) {}
    })();
  }, []);

  const saveSingle = async (key: string, multi: Single) => {
    try {
      const parsedMulti = multiLanguageStringSchema.parse(multi);
      await remoteCall("stringUpdate", { key, multi: parsedMulti });
      const call = await remoteCall("stringList", {});
      if (call.strings) setItems(call.strings);
    } catch (error) {
      if ((error as Error).name === "ZodError") {
        console.log(error);
      }
      console.log(error);
    }
  }; 

  
  return <div className="Strings screen">
    <h1>Strings</h1>
    

    {items 
      && Object.keys(items).map((singleKey) => 
        <div key={singleKey}>
          <SingleForm saveSingle={saveSingle} givenCurrent={items[singleKey]} givenKey={singleKey} />
          <hr />
        </div>,
      )}
  </div>;
};
