import "./index.scss";

import { ReactComponent as LogoSvg } from "./logo.svg";
import { ReactComponent as AppleSvg } from "./apple.svg";
/* import { ReactComponent as FacebookSvg } from "./facebook.svg"; */
import { ReactComponent as GoogleSvg } from "./google.svg";

export const Login = () => {
  
  return <div className="Login">
    <div className="top-wrapper">
      <div className="inner-wrapper">
        <h1>Login</h1>
        <div className="methods">
          <a href={`${process.env.REACT_APP_BASE_AUTH_URL}/login/google?redirectEndpoint=${encodeURIComponent(window.location.href)}`}>
            <div className="icon"><GoogleSvg /></div>
            <div className="text">Login with Google</div>
          </a>
          <a href={`${process.env.REACT_APP_BASE_AUTH_URL}/login/apple?redirectEndpoint=${encodeURIComponent(window.location.href)}`}>
            <div className="icon"><AppleSvg /></div>
            <div className="text">Login with Apple</div>
          </a>
          {/* <a href={`${process.env.REACT_APP_BASE_AUTH_URL}/login/facebook?redirectEndpoint=${encodeURIComponent(window.location.href)}`}>
            <div className="icon"><FacebookSvg /></div>
            <div className="text">{multiLanguageString(getString("loginWith"))} Facebook</div>
          </a> */}
        </div>
      </div>
    </div>
    <div className="bottom-wrapper">
      <LogoSvg />
    </div>
  </div>;
};
