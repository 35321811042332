import { useEffect, useState } from "react";
import { User, remoteCall } from "../../App";
import cloneDeep from "clone-deep";

import "./index.scss";
import { mediaSchema as schema } from "../../types/media";
import { z } from "zod";
import { multiLanguageString } from "../../utils/multiLanguageString";
import { ListItem } from "../../components/ListItem";
import { Modal } from "../../components/Modal";
import { TextField } from "../../components/TextField";
import { changeHandlers } from "../../utils/changeHandlers";
import { MultiLanguageTextField } from "../../components/MultiLanguageTextField";
import { RepeatingFields } from "../../components/RepeatingFields";
import { SelectionBox } from "../../components/ListBox";
import { ToggleField } from "../../components/ToggleField";

type Single = z.infer<typeof schema>;

/* const emptySingle: Single = {
  _id: "",
  title: [],
  slides: [],
  hideOutsideExperience: false,
  relatedLinks: [],
}; */

const Form = ({ givenCurrent, saveSingle, deleteSingle }: { givenCurrent: Single, saveSingle: (newSingle: Single) => void, deleteSingle: () => {} }) => {
  const [current, setCurrent] = useState(cloneDeep(givenCurrent));

  const { parseTextChange, parseSingleSelectionChange, parseBooleanChange, addItem, removeItem } = changeHandlers(setCurrent);

  return <div className="form">
    {current._id !== "" && <>
      <TextField label="ID" value={current._id} onChange={parseTextChange("_id")} isReadOnly />
      <hr/>
    </>}

    <TextField label="Analytics name" value={current.analyticsName} onChange={parseTextChange("analyticsName")} />

    <MultiLanguageTextField 
      label="Title"
      property="title"
      items={current.title}
      {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
    />

    <ToggleField
      label="Hide outside experience"
      isSelected={current.hideOutsideExperience || false}
      onChange={parseBooleanChange("hideOutsideExperience")}
    />

    <div>
      <RepeatingFields
        label="Slides"
        items={current.slides}
        /* disableDelete={true}
        disableAdd={true} */
        itemElement={(item, index) => <>
          <SelectionBox
            label="Type"
            selectionMode="single"
            defaultSelectedKeys={[item.type] || []}
            onSelectionChange={parseSingleSelectionChange(`slides.${index}.type`)}
            items={[{ label: "Video", value: "video" }, { label: "Image", value: "image" }, { label: "File", value: "file" }, { label: "Audio", value: "audio" }]}
          />
          <MultiLanguageTextField
            label="File URL"
            property={`slides.${index}.fileUrl`}
            items={current.slides[index].fileUrl}
            {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}
          />
          <MultiLanguageTextField
            label="Description"
            property={`slides.${index}.description`}
            items={current.slides[index].description || []}
            {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}
          />
          <MultiLanguageTextField
            label="Preview"
            property={`slides.${index}.preview`}
            items={current.slides[index].preview || []}
            {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}
          />
        </>}
        addItemFunction={() => {addItem("slides", { type: "video", fileUrl: [] });}}
        removeItemFunction={(index: number) => {removeItem("slides", index);}}
      />
    </div>

    <div>
      <RepeatingFields
        disableAdd
        disableDelete
        label="Related links"
        items={current.relatedLinks || []}
        itemElement={(item, index) => <>
          <MultiLanguageTextField
            label="Text"
            property={`relatedLinks.${index}.text`}
            items={(current.relatedLinks || [])[index].text}
            {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}
          />
          <MultiLanguageTextField
            readonly
            label="URL"
            property={`relatedLinks.${index}.url`}
            items={(current.relatedLinks || [])[index].url}
            {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}
          />
          <ToggleField
            label="Hide outside experience"
            isSelected={(current.relatedLinks || [])[index].hideOutsideExperience || false}
            onChange={parseBooleanChange(`relatedLinks.${index}.hideOutsideExperience`)}
          />
        </>}
        addItemFunction={() => {addItem("slides", { type: "video", fileUrl: [] });}}
        removeItemFunction={(index: number) => {removeItem("slides", index);}}
      />
    </div>

    <div className="modalActions">
      <button onClick={() => {saveSingle(current);}}>Save</button>
      {current._id !== "" && <button onClick={() => {deleteSingle();}}>Delete</button>}
    </div>
  </div>;
};

export const Medias = (
  { user }: 
  { user: User }) => {
  if (!user.privileges.includes("medias")) return <div>Access denied</div>;

  const [items, setItems] = useState<Single[]>();
  
  const [givenCurrent, setGivenCurrent] = useState<Single | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const call = await remoteCall("mediaList", {});
        if (call.medias) setItems(call.medias);
      } catch (error) {}
    })();
  }, []);

  const openSingle = (single: Single) => {
    setGivenCurrent(single);
  };

  const closeSingle = () => {
    setGivenCurrent(undefined);
  };

  const saveSingle = async (newSingle: Single) => {
    try {
      const parsedSingle = schema.parse(newSingle);
      await remoteCall("mediaUpdate", parsedSingle);
      console.log(parsedSingle);
      const call = await remoteCall("mediaList", {});
      if (call.medias) setItems(call.medias);
      closeSingle();
    } catch (error) {
      if ((error as Error).name === "ZodError") {
        console.log(error);
      }
      console.log(error);
    }
  }; 

  const deleteSingle = async () => {
    if (givenCurrent && givenCurrent._id) {
      const confirm = window.confirm("Do you really want to delete?");
      if (confirm) {
        await remoteCall("mediaDelete", { _id: givenCurrent._id });
        const call = await remoteCall("mediaList", {});
        if (call.medias) setItems(call.medias);
        closeSingle();
      }
    }
  };

  /* const createNew = () => {
    setGivenCurrent(emptySingle);
  }; */
  
  return <div className="Medias screen">
    <h1>Medias</h1>
    {/* <div className="actions">
      <button onClick={createNew}>New</button>
    </div> */}
    
    {items 
      && items.map((single) => 
        <ListItem 
          key={single._id} 
          title={multiLanguageString(single.title)} 
          onClick={() => openSingle(single)}
        />,
      )}
    
    <Modal closeModal={closeSingle} isOpen={!!givenCurrent} title="Edit media">
      {givenCurrent && <Form {...{ givenCurrent, saveSingle, deleteSingle }} />}
    </Modal>
  </div>;
};
