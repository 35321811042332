import { ReactNode } from "react";
import ReactModal from "react-modal";

import "./index.scss";
import { CloseSvg } from "../CloseSvg";

export const Modal = ({ children, closeModal, isOpen, title }: { children: ReactNode, closeModal: () => void, isOpen: boolean, title: string }) => {
  ReactModal.setAppElement("#root");

  return <ReactModal
    className="ModalContent"
    overlayClassName="Modal"
    isOpen={isOpen}
    onRequestClose={closeModal}
  >
    <div className="top">
      <h1>{title}</h1>
      <button title="close" onClick={closeModal}><CloseSvg /></button>
    </div>
    {children}
  </ReactModal>;
};
