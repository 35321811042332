import objectPath from "object-path";
import { SetStateAction } from "react";
import { Selection as StatelySelection } from "react-stately";
import { Object as Obj, String as Str } from "ts-toolbelt";

export const changeHandlers = <S extends object | any[]>(setState: (value: SetStateAction<S>) => void) => {
  const parseTextChange = (key: string) => (value: string) => {
    setState((prev) => {
      objectPath.set(prev, key, value);
      return { ...prev };
    });
  };

  const parseNumberChange = (key: string) => (value: number) => {
    setState((prev) => {
      objectPath.set(prev, key, value);
      return { ...prev };
    });
  };

  const parseSingleSelectionChange = (key: string) => (values: StatelySelection) => {
    setState((prev) => {
      objectPath.set(prev, key, Array.from(values)[0]);
      return { ...prev };
    });
  };

  const parseMultipleSelectionChange = (key: string) => (values: StatelySelection | string[] | number[]) => {
    setState((prev) => {
      objectPath.set(prev, key, Array.from(values));
      return { ...prev };
    });
  };

  const parsePositionChange = (key: string) => (position: { lat: number, lng: number }) => {
    setState((prev) => {
      objectPath.set(prev, key, position);
      return { ...prev };
    });
  };

  const parseBooleanChange = (key: string) => (value: boolean) => {
    setState((prev) => {
      objectPath.set(prev, key, value);
      return { ...prev };
    });
  };
  
  const addItem = <Key extends string>(key: Key, emptyItem: (Obj.Path<S, Str.Split<Key, ".">> extends Array<any> ? Obj.Path<S, Str.Split<Key, ".">> : never)[number]) => {
    setState((prev) => {
      objectPath.push(prev, key, emptyItem);
      return { ...prev };
    });
  };

  const removeItem = (key: string, index: number) => {
    setState((prev) => {
      objectPath.del(prev, `${key}.${index}`);
      return { ...prev };
    });
  };

  return { parseTextChange, parseNumberChange, parseSingleSelectionChange, parseMultipleSelectionChange, parsePositionChange, parseBooleanChange, addItem, removeItem };
};
