import { z } from "zod";
import { multiLanguageStringSchema } from "./multiLanguageString";

export const characterSchema = z.object({
  _id: z.string(),
  name: multiLanguageStringSchema,
  moods: z.object({
    shortName: z.string(),
    image: z.string(),
  }).array(),
});
