import { makeRemoteCallHandler } from "./libProcedureWire";
import type { Endpoints } from "../../backend";

import "./App.scss";
import { UserType } from "../../backend/procedures/me";
import { useEffect, useState } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { Login } from "./screens/Login";

import { ReactComponent as Logo } from "./logo.svg";
import { LoadScript } from "@react-google-maps/api";

import { Pinpoints } from "./screens/Pinpoints";
import { Medias } from "./screens/Medias";
import { Museums } from "./screens/Museums";
import { Items } from "./screens/Items";
import { Characters } from "./screens/Characters";
import { Stations } from "./screens/Stations";
import { Moments } from "./screens/Moments";
import { Strings } from "./screens/Strings";
import { OnboardingStations } from "./screens/OnboardingStations";
import { Users } from "./screens/Users";

export type User = UserType;
export const remoteCall = makeRemoteCallHandler<Endpoints>();
export type RemoteCallHandler = typeof remoteCall;

function App() {
  const [logged, setLogged] = useState<boolean>(false);
  const [needsLogin, setNeedsLogin] = useState<boolean>(false);
  const [/* token*/, setToken] = useState<string>();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const urlToken = searchParams.get("token");

      if (urlToken && urlToken !== "") {
        localStorage.setItem("sesameToken", urlToken);
        window.history.replaceState({}, document.title, window.location.pathname);
      }

      const localToken = localStorage.getItem("sesameToken");
      if (localToken) {
        setLogged(true);
        setToken(localToken);
      } else {
        setNeedsLogin(true);
      }

      try {
        const meCall = await remoteCall("me", {});
        if (!meCall.user) return;
        setUser(meCall.user);
      } catch (error) {}
    })();
  }, []);

  const logout = () => {
    localStorage.removeItem("sesameToken");
    location.reload();
  };

  return <BrowserRouter>
    {(logged && user) 
      ? <>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY as string}
          id="google-map-script"
          language="it"
          loadingElement={<div></div>}
        >
          <header id="header">
            <div className="left">
              <Logo />
            </div>
            <div className="right">
              <nav>
                {user.privileges.includes("pinpoints") ? <Link to="/pinpoints">Pinpoints</Link> : null}
                {user.privileges.includes("medias") ? <Link to="/medias">Medias</Link> : null}
                {user.privileges.includes("museums") ? <Link to="/museums">Museums</Link> : null}
                {user.privileges.includes("items") ? <Link to="/items">Items</Link> : null}
                {user.privileges.includes("characters") ? <Link to="/characters">Characters</Link> : null}
                {user.privileges.includes("strings") ? <Link to="/strings">Strings</Link> : null}
                {user.privileges.includes("stations") ? <Link to="/onboardingStations">Onboarding stations</Link> : null}
                {user.privileges.includes("userListing") ? <Link to="/users">Users</Link> : null}
                <button onClick={logout}>Logout</button>
              </nav>
            </div>
          </header>
          <main>
            <Routes>
              <Route path="/pinpoints" element={<Pinpoints {...{ user }} />} />
              <Route path="/medias" element={<Medias {...{ user }} />} />
              <Route path="/museums" element={<Museums {...{ user }} />} />
              <Route path="/items" element={<Items {...{ user }} />} />
              <Route path="/characters" element={<Characters {...{ user }} />} />
              <Route path="/museums/:museumId/stations" element={<Stations {...{ user }} />} />
              <Route path="/museums/:museumId/stations/:stationId" element={<Moments type="station" {...{ user }} />} />
              <Route path="/strings" element={<Strings {...{ user }} />} />
              <Route path="/onboardingStations" element={<OnboardingStations {...{ user }} />} />
              <Route path="/onboardingStations/:stationId" element={<Moments type="onboardingStation" {...{ user }} />} />
              <Route path="/users" element={<Users {...{ user }} />} />
            </Routes>
          </main>
        </LoadScript>
      </>
      : 
      <Routes>
        <Route path="*" element={needsLogin ? <Login /> : null} />
      </Routes>}
  </BrowserRouter>;
}

export default App;
