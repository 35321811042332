import { useEffect, useState } from "react";
import { User, remoteCall } from "../../App";
import cloneDeep from "clone-deep";

import "./index.scss";
import { stationSchema as schema } from "../../types/station";
import { museumSchema } from "../../types/museum";
import { z } from "zod";
import { multiLanguageString } from "../../utils/multiLanguageString";
import { ListItem } from "../../components/ListItem";
import { Modal } from "../../components/Modal";
import { TextField } from "../../components/TextField";
import { changeHandlers } from "../../utils/changeHandlers";
import { Link, useParams } from "react-router-dom";
import { MultiLanguageTextField } from "../../components/MultiLanguageTextField";
import { MultiLanguageBase64ImageField } from "../../components/MultiLanguageBase64ImageField";
import { ToggleField } from "../../components/ToggleField";

type Single = z.infer<typeof schema>;
type Museum = z.infer<typeof museumSchema>;

/* const emptySingle: Single = {
  _id: "",
  name: [],
  position: {
    lat: 0,
    lng: 0,
  },
  medias: [],
}; */

const Form = ({ givenCurrent, saveSingle /* deleteSingle */ }: { givenCurrent: Single, saveSingle: (newSingle: Single) => void, /* deleteSingle: () => {} */ }) => {
  const [current, setCurrent] = useState(cloneDeep(givenCurrent));

  const { parseTextChange, parseSingleSelectionChange, parseBooleanChange, addItem, removeItem } = changeHandlers(setCurrent);

  return <div className="form">
    <Link className="furtherLink" to={`/museums/${current.museumId}/stations/${current._id}`}>Browse moments of this station</Link>

    {current._id !== "" && <>
      <TextField label="ID" value={current._id} onChange={parseTextChange("_id")} isReadOnly />
      <a className="liveLink" href={`${process.env.REACT_APP_APP_URL}/station/${current._id}`}>Live station &gt;</a>
      <hr/>
    </>}

    <TextField label="Analytics name" value={current.analyticsName} onChange={parseTextChange("analyticsName")} />

    <MultiLanguageTextField
      label="Name"
      property="name"
      items={current.name || []}
      {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
    />

    <MultiLanguageBase64ImageField
      label="Image"
      property="image"
      items={current.image || []}
      format="image/jpeg"
      description="Squared image. JPEG. Min width 900px."
      rasterResizeOptions={{ width: 900 }}
      {...{ addItem, removeItem, parseTextChange, parseSingleSelectionChange }}    
    />

    <hr />

    <ToggleField
      label="Initial"
      isSelected={current.initial || false}
      onChange={parseBooleanChange("initial")}
      isReadOnly
    />

    <ToggleField
      label="Final"
      isSelected={current.final || false}
      onChange={parseBooleanChange("final")}
      isReadOnly
    />

    <div className="modalActions">
      <button onClick={() => {saveSingle(current);}}>Save</button>
      {/* {current._id !== "" && <button onClick={() => {deleteSingle();}}>Delete</button>} */}
    </div>
  </div>;
};

export const Stations = (
  { user }: 
  { user: User }) => {
  if (!user.privileges.includes("stations")) return <div>Access denied</div>;

  const [items, setItems] = useState<Single[]>();
  const [museums, setMuseums] = useState<Museum[]>();
  const [museumId, setMuseumId] = useState<string>();
  
  const [givenCurrent, setGivenCurrent] = useState<Single | undefined>();

  const { museumId: queryMuseumId } = useParams();

  useEffect(() => {setMuseumId(queryMuseumId);}, [queryMuseumId]);

  useEffect(() => {
    (async () => {
      if (museumId) {
        try {
          const museumCall = await remoteCall("museumList", {});
          if (museumCall.museums) setMuseums(museumCall.museums);
  
          const call = await remoteCall("stationList", { museum: museumId });
          if (call.stations) setItems(call.stations);
        } catch (error) {}
      }
    })();
  }, [museumId]);

  const openSingle = (single: Single) => {
    setGivenCurrent(single);
  };

  const closeSingle = () => {
    setGivenCurrent(undefined);
  };

  const saveSingle = async (newSingle: Single) => {
    try {
      const { _id: id, name, image } = schema.parse(newSingle);
      await remoteCall("stationUpdate", { _id: id, name, image });
      const call = await remoteCall("stationList", { museum: museumId, forceRenewCache: true });
      if (call.stations) setItems(call.stations);
      closeSingle();
    } catch (error) {
      if ((error as Error).name === "ZodError") {
        console.log(error);
      }
      console.log(error);
    }
  }; 

  /* const deleteSingle = async () => {
    if (givenCurrent && givenCurrent._id) {
      const confirm = window.confirm("Do you really want to delete?");
      if (confirm) {
        await remoteCall("pinpointDelete", { _id: givenCurrent._id });
        const call = await remoteCall("pinpointList", {});
        if (call.pinpoints) setItems(call.pinpoints);
        closeSingle();
      }
    }
  }; */

  /* const createNew = () => {
    setGivenCurrent(emptySingle);
  }; */
  
  return <div className="Stations screen">
    <h1>Stations</h1>
    <span className="subtitle">{multiLanguageString(museums?.find((singleMuseum) => singleMuseum._id === museumId)?.name)}</span>
    {/* <div className="actions">
      <button onClick={createNew}>New</button>
    </div> */}
    
    {items 
      && items.filter((single) => single.museumId === museumId).map((single) => 
        <ListItem 
          key={single._id} 
          title={`${multiLanguageString(single.name) || "<untitled>"}${single.initial ? " (initial)" : single.final ? " (final)" : ""}`} 
          onClick={() => openSingle(single)}
        />,
      )}
    
    <Modal closeModal={closeSingle} isOpen={!!givenCurrent} title="Edit station">
      {givenCurrent && <Form {...{ givenCurrent, saveSingle }} />}
    </Modal>
  </div>;
};
