import { GoogleMap, Marker } from "@react-google-maps/api";
import { useState } from "react";
import { mapStyles } from "./mapStyles";
import { NumberField } from "../NumberField";

import "./index.scss";

export const MapField = ({ position, onChange, label }: { position: { lat: number, lng: number }, onChange: (position: { lat: number, lng: number }) => void, label?: string }) => {
  const [center] = useState(position);
  const [currentPosition, setCurrentPosition] = useState(position);

  const changeHandler = (newPosition: { lat: number | undefined, lng: number | undefined }) => {
    setCurrentPosition((prev) => {
      onChange({ lat: newPosition.lat || prev.lat, lng: newPosition.lng || prev.lng });
      return { lat: newPosition.lat || prev.lat, lng: newPosition.lng || prev.lng };
    });
  };

  return <div className="MapField">
    <label>{label || ""}</label>
    <GoogleMap
      mapContainerClassName="map"
      mapContainerStyle={{
        width: "100%",
        height: "100%",
      }}
      center={center}
      zoom={12.2}
      //onLoad={(currentMap) => setMap(currentMap)}
      //onZoomChanged={() => {if ((map?.getZoom() || 10.2) < 10.5 || (map?.getZoom() || 10.2) > 11.5) setFar((map?.getZoom() || 10.2) < 12);}}
      onClick={(event) => {changeHandler({ lat: event.latLng?.lat(), lng: event.latLng?.lng() });}}
      options={{
        fullscreenControl: false,
        streetViewControl: false,
        keyboardShortcuts: false,
        styles: mapStyles,
        restriction: {
          latLngBounds: {
            north: 46.831157,
            south: 45.175114,
            east: 10.363009,
            west: 8.112288,
          },
          strictBounds: true,
        },
        isFractionalZoomEnabled: true,
      }}
    >
      <Marker position={currentPosition} />
    </GoogleMap>
    <div className="fields">
      <NumberField
        label="Latitude" value={currentPosition.lat} onChange={(newLat) => {changeHandler({ lat: newLat, lng: undefined });}} formatOptions={{ maximumFractionDigits: 6 }}
      />
      <NumberField
        label="Longitude" value={currentPosition.lng} onChange={(newlng) => {changeHandler({ lng: newlng, lat: undefined });}} formatOptions={{ maximumFractionDigits: 6 }}
      />
    </div>
  </div>;
};
